<template>
  <div>
    <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col cols="12" md="12">
        <div>
          <v-footer tile flat height="80" class="font-weight-medium shadow-md"
            :class="$vuetify.breakpoint.xsOnly ? 'px-0' : ''" :color="$vuetify.theme.dark ? 'dark' : 'white'"
            :dark="$vuetify.theme.dark" :inset="true">
            <v-col class="text-left py-0" cols="12">
              <v-row class="py-0">
                <v-col :cols="$vuetify.breakpoint.smAndUp ? 2 : 3" class="py-0"
                  :class="$vuetify.breakpoint.xsOnly ? 'px-2' : ''">
                  <div>
                    <v-select id="matchdaySelect" :items="matchdays" v-model="matchdayFilter" @change="fixtureList()"
                      label="Matchday">
                      <template v-slot:selection="{ item }" v-if="$vuetify.breakpoint.xsOnly">
                        <div style="display: flex; align-items: center;">
                          <span>{{ item.replace("Matchday", "").trim() }}</span>
                        </div>
                      </template>
                    </v-select>
                  </div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.smAndUp ? 2 : 4" class="py-0"
                  :class="$vuetify.breakpoint.xsOnly ? 'px-2' : ''">
                  <div>
                    <v-select id="competitionSelect" :items="competitions" v-model="competitionFilter"
                      @change="fixtureList()" label="Competition" />
                  </div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.smAndUp ? 2 : 5" class="py-0"
                  :class="$vuetify.breakpoint.xsOnly ? 'px-2' : ''">
                  <div>
                    <v-select id="competitionSelect" :items="teamList" v-model="selectedTeam" @change="fixtureList()"
                      label="Team" />
                  </div>
                </v-col>
                <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
                <v-col cols="4" :sm="2" v-if="$vuetify.breakpoint.smAndUp" class="py-0">
                  <div>
                    <v-menu ref="fromMenu" v-model="fromMenu" :close-on-content-click="false"
                      transition="scale-transition" min-width="auto" left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="formattedDateFrom" label="From"
                          :prepend-icon="$vuetify.breakpoint.smAndDown ? '' : 'mdi-calendar'" readonly multiple
                          clearable v-bind="attrs" v-on="on" />
                      </template>
                      <v-date-picker v-model="dateFrom" no-title scrollable @change="fixtureList()"
                        @input="fromMenu = false" :format="['d/M/yyyy']">
                      </v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
                <v-col cols="4" :sm="2" v-if="$vuetify.breakpoint.smAndUp" class="py-0">
                  <div>
                    <v-menu ref="toMenu" v-model="toMenu" :close-on-content-click="false" transition="scale-transition"
                      min-width="auto" left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="formattedDateTo" label="To"
                          :prepend-icon="$vuetify.breakpoint.smAndDown ? '' : 'mdi-calendar'" readonly multiple
                          clearable v-bind="attrs" v-on="on" />
                      </template>
                      <v-date-picker v-model="dateTo" no-title scrollable @change="fixtureList()"
                        @input="toMenu = false" :format="['d/M/yyyy']">
                      </v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-footer>
        </div>
        <div v-if="filteredFixtures.length > 0">
          <base-card v-for="stage in competitionStages" :key="stage" class="mt-2">
            <v-card-title v-if="filteredFixtures.length > 0">{{ stage }}</v-card-title>
            <v-card-text v-if="filteredFixtures.length > 0" :class="$vuetify.breakpoint.xsOnly ? 'pa-1' : ''">
              <v-data-table :headers="activeHeaders" :items="filteredFixtures.filter(it => it.stage == stage)"
                :footer-props="{
                  itemsPerPageText: 'Display'
                }" class="elevation-1" group-by="matchday" :custom-sort="customSort" :hide-default-footer="hideFooter"
                mobile-breakpoint="0">
                <template v-slot:group.header="item">
                  <td :colspan="headers.length">
                    <div class="header-parent">
                      <div class="inline-block">
                        <div>
                          <div>
                            {{ item.items[0].matchdayLabel }}
                            <v-btn class="positioningElement" />
                          </div>
                        </div>
                      </div>
                      <div class="inline-block">
                        <div class="grouper">
                          <div>
                            <v-btn icon @click="item.toggle">
                              <v-icon>{{ item.isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </template>

                <template v-slot:item.homeLogo="{ item }">
                  <span style="width: 100%; height: 100%; cursor: pointer;" @click="goToProfile(item.homeTeamId)">
                    <v-img :src="`/assets/gaa/teams/icons/${item.homeLogo}.WEBP`" :alt="item.homeLogo" contain
                      max-width="30" class="img_border cursor-pointer"></v-img></span>
                </template>
                <template v-slot:item.home="{ item }">
                  <h6 class="ma-0 font-weight-medium cursor-pointer" @click="goToProfile(item.homeTeamId)">
                    {{ item.homeTeam }}
                  </h6>
                </template>

                <template v-slot:item.date="{ item }">
                  {{ formatDateUS(item.date) }}
                </template>

                <template v-slot:item.time="{ item }">
                  {{ matchTimeString(item) }}
                </template>

                <template v-slot:item.venue="{ item }">
                  {{ $vuetify.breakpoint.xsOnly ? item.venue.substring(item.venue.indexOf(',') + 1) : item.venue }}
                </template>

                <template v-slot:item.away="{ item }">
                  <h6 class="ma-0 font-weight-medium cursor-pointer" @click="goToProfile(item.awayTeamId)">
                    {{ item.awayTeam }}
                  </h6>
                </template>
                <template v-slot:item.awayLogo="{ item }">
                  <span style="width: 100%; height: 100%; cursor: pointer;" @click="goToProfile(item.awayTeamId)">
                    <v-img :src="`/assets/gaa/teams/icons/${item.awayLogo}.WEBP`" :alt="item.awayLogo" contain
                      max-width="30" class="img_border cursor-pointer"></v-img>
                  </span>
                </template>
                <template v-slot:item.coverage="{ item }">
                  <div>
                    <v-img contain :max-width="$vuetify.breakpoint.xsOnly ? 50 : 80"
                      :src="`/assets/tv/logo/${item.coverage}.jpg`" :alt="item.coverage" />
                  </div>
                </template>
                <template v-slot:item.history="{ item }">
                  <td v-if="item.homeTeamId && item.awayTeamId" class="icon-column cursor-pointer"
                    @click="goToHistory(item)">
                    <v-icon>mdi-calendar-clock-outline</v-icon>
                  </td>
                  <td v-else></td>
                </template>
              </v-data-table>
            </v-card-text>
          </base-card>
        </div>
        <div v-else>
          <base-card>
            <v-card-text>
              No data available
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-spacer />
                <v-col class="d-flex justify-center">
                  <v-btn :plain="!$vuetify.theme.dark" text
                    @click="$route.meta.type == 'fixtures' ? goToResults() : goToFixtures()">
                    Show {{ $route.meta.type == 'fixtures' ? 'Results' : 'Fixtures' }}
                  </v-btn>
                </v-col>
                <v-spacer />
              </v-row>
            </v-card-actions>
          </base-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'Fixtures',
  },
  async mounted() {
    this.populateQueryParams();
    await this.fetchFixtures(this.$store.getters.getCompetitionId);
    this.loading = false;
  },
  data() {
    return {
      loading: true,
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'homeLogo',
        },
        { text: 'Home', value: 'home' },
        { text: '', value: 'time' },

        { text: 'Away', value: 'away' },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'awayLogo',
        },
        { text: 'Date', value: 'date' },
        { text: 'Matchday', value: 'matchday' },
        { text: 'Venue', value: 'venue' },
        { text: 'TV', value: 'coverage' },
        { text: 'History', value: 'history', class: 'history-header', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      matchdayFilter: 'All',
      competitionFilter: 'All',
      dateFrom: '',
      dateTo: '',
      fromMenu: false,
      toMenu: false,
      selectedTeam: 'All',
      filteredFixtures: [],
      hideFooter: false
    }
  },
  computed: {
    ...mapGetters(["getFixtures"]),
    teamList() {
      return ['All', ...this.getFixtures.map(it => it.homeTeam).filter(it => it != 'TBD')]
    },
    activeHeaders() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return this.headers
      } else {
        return [{ text: 'Home', value: 'home', sortable: false },
        { text: '', value: 'time', sortable: false },
        { text: 'Away', value: 'away', sortable: false },
        { text: 'Date', value: 'date', sortable: false },
        { text: '', value: 'history', class: 'history-header', sortable: false },
        ]
      }
    },
    competitionStages() {
      if (this.$route.meta.type == 'fixtures') {
        return [...this.filteredFixtures
          .filter(fixture => fixture.matchResult === null) // Filter fixtures where matchResult is null
          .reduce((set, fixture) => set.add(fixture.stage), new Set())
        ];
      } else {
        return [...this.filteredFixtures
          .filter(fixture => fixture.matchResult !== null) // Filter fixtures where matchResult is null
          .reduce((set, fixture) => set.add(fixture.stage), new Set())
        ];
      }
    },
    matchdays() {
      let matchdays = [];
      if (this.$route.meta.type == 'fixtures') {
        matchdays = [
          ...this.getFixtures
            .filter(fixture => fixture.matchResult == null)
            .reduce((set, fixture) => set.add(`Matchday ${fixture.matchday}`), new Set())
        ].sort();
      } else {
        matchdays = [
          ...this.getFixtures
            .filter(fixture => fixture.matchResult !== null)
            .reduce((set, fixture) => set.add(`Matchday ${fixture.matchday}`), new Set())
        ].sort();
      }
      if (matchdays.length > 1) {
        matchdays.unshift('All');
      } else if (matchdays.length == 0) {
        matchdays[0] = 'All';
      } else {
        this.matchdayFilter = matchdays[0];
      }
      matchdays.sort((a, b) => {
        if (a === "All") return -1;
        if (b === "All") return 1;

        const aMatchday = a === "All" ? 0 : parseInt(a.split(' ')[1], 10);
        const bMatchday = b === "All" ? 0 : parseInt(b.split(' ')[1], 10);
        if (this.$route.meta.type == 'fixtures') {
          return aMatchday - bMatchday;
        } else {
          return bMatchday - aMatchday;
        }
      });
      return matchdays;
    },
    competitions() {
      let competitions = [];
      if (this.$route.meta.type == 'fixtures') {
        competitions = [
          ...this.getFixtures
            .filter(fixture => fixture.matchResult == null)
            .reduce((set, fixture) => set.add(`${fixture.stage}`), new Set())
        ].sort();
      } else {
        competitions = [
          ...this.getFixtures
            .filter(fixture => fixture.matchResult !== null)
            .reduce((set, fixture) => set.add(`${fixture.stage}`), new Set())
        ].sort();
      }
      if (competitions.length > 1) {
        competitions.unshift('All');
      } else if (competitions.length == 0) {
        competitions[0] = 'All';
      } else {
        this.competitionFilter = competitions[0];
      }
      return competitions;
    },
    formattedDateTo: {
      get: function () {
        return this.dateTo ? this.formatDate(this.dateTo) : '';
      },
      set: function (newValue) {
        this.dateTo = newValue;
        this.fixtureList();
      }
    },
    formattedDateFrom: {
      get: function () {
        return this.dateFrom ? this.formatDate(this.dateFrom) : '';
      },
      set: function (newValue) {
        this.dateFrom = newValue;
        this.fixtureList();
      }
    }
  },
  methods: {
    ...mapActions([
      "fetchFixtures"
    ]),
    goToProfile(id) {
      this.$router.push(`/app/${this.$router.currentRoute.meta.sport}/statistics/profile/${id}`)
    },
    goToFixtures() {
      const currentQuery = this.$route.query;
      this.$router.push({
        path: `/app/${this.$router.currentRoute.meta.sport}/matches/fixtures`,
        query: currentQuery,
      });
    },
    goToResults() {
      const currentQuery = this.$route.query;
      this.$router.push({
        path: `/app/${this.$router.currentRoute.meta.sport}/matches/results`,
        query: currentQuery,
      });
    },
    populateQueryParams() {
      if (this.$router.currentRoute.query.matchday) {
        this.matchdayFilter = this.$router.currentRoute.query.matchday == 'All' ? 'All' : "Matchday " + this.$router.currentRoute.query.matchday;
      }
      if (this.$router.currentRoute.query.competition) {
        this.competitionFilter = this.$router.currentRoute.query.competition;
      }
      if (this.$router.currentRoute.query.team) {
        this.selectedTeam = this.$router.currentRoute.query.team;
      }
      if (this.$router.currentRoute.query.from) {
        this.dateFrom = this.$router.currentRoute.query.from;
      }
      if (this.$router.currentRoute.query.to) {
        this.dateTo = this.$router.currentRoute.query.to;
      }
    },
    fixtureList() {
      let fixtureList = this.$store.getters['getFixtures'];
      let filteredFixturesList;

      if (this.$route.meta.type == 'fixtures') {
        filteredFixturesList = fixtureList.filter((fixture) => !fixture.matchResult);
      } else {
        filteredFixturesList = fixtureList.filter((fixture) => fixture.matchResult);
      }
      if (!(!this.matchdayFilter || this.matchdayFilter == 'All')) {
        filteredFixturesList = filteredFixturesList.filter((fixture) => fixture.matchday == this.matchdayFilter.substring(9));
      }

      if (this.stage && this.stage != 'All') {
        filteredFixturesList = filteredFixturesList.filter((it) => it.stage == stage)
      }

      if (this.dateFrom) {
        filteredFixturesList = filteredFixturesList.filter((fixture) => this.compareDates(this.dateFrom, fixture.date) <= 0);
      }
      if (this.dateTo) {
        filteredFixturesList = filteredFixturesList.filter((fixture) => this.compareDates(this.dateTo, fixture.date) >= 0);
      }
      if (this.selectedTeam && this.selectedTeam != 'All') {
        filteredFixturesList = filteredFixturesList.filter(it => it.homeTeam == this.selectedTeam || it.awayTeam == this.selectedTeam);
      }
      if (this.competitionFilter && this.competitionFilter != 'All') {
        filteredFixturesList = filteredFixturesList.filter((fixture) => fixture.stage == this.competitionFilter);
      }
      if (this.$route.meta.type == 'fixtures') {
        filteredFixturesList.sort((a, b) => {
          // First, sort by matchday
          if (a.matchday !== b.matchday) {
              return a.matchday - b.matchday;
          }
          console.log("using date")
          // If matchday is the same, sort by date
          return a.fullDate - b.fullDate;
        });
      } else {
        filteredFixturesList.sort((a, b) => {
          // First, sort by matchday
          if (a.matchday !== b.matchday) {
              return b.matchday - a.matchday;
          }
          // If matchday is the same, sort by date
          return a.fullDate - b.fullDate;
        });
      }
      this.hideFooter = filteredFixturesList.length < 9;
      this.filteredFixtures = filteredFixturesList;
    },
    formatDate(date) {
      return date.split('-').reverse().map((part, index) => index === 2 ? part.slice(-2) : part).join('/');
    },
    formatDateUS(date) {
      return date.replace(/(\d+)\/(\d+)\/(\d{2})(\d{2})/, (_, m, d, __, y) => `${d.padStart(2, '0')}/${m.padStart(2, '0')}/${y}`);
    },
    matchTimeString(item) {
      return item.matchResult ? item.homeGoals + ":" + item.homePoints + ' - ' + item.awayGoals + ":" + item.awayPoints : item.time
    },
    compareDates(yyyymmdd, mdy) {
      // Convert YYYY-MM-DD to a Date object
      const [year1, month1, day1] = yyyymmdd.split('-').map(Number);
      const date1 = new Date(year1, month1 - 1, day1); // Months are 0-indexed in JS Date

      // Convert M/D/YYYY to a Date object
      const [month2, day2, year2] = mdy.split('/').map(Number);
      const date2 = new Date(year2, month2 - 1, day2); // Months are 0-indexed in JS Date

      if (date1 < date2) return -1;
      if (date1 > date2) return 1;
      return 0;  // dates are equal
    },
    goToHistory(item) {
      this.$router.push(`/app/${this.$router.currentRoute.meta.sport}/statistics/headtohead?team=${item.homeTeamId}&opposition=${item.awayTeamId}`)
    },
    customSort(items, sortBy, sortDesc, groupBy, groupDesc) {
      // Custom sorting function to sort the groups in descending order
      if (groupBy.length) {
        const groupedItems = items.sort((a, b) => {
          // Ensure `groupDesc` is considered
          if (groupDesc[0]) {
            return b[groupBy[0]] - a[groupBy[0]];
          }
          return a[groupBy[0]] - b[groupBy[0]];
        });

        // Return sorted items
        return groupedItems;
      }
      return items;
    }
  },
  watch: {
    getFixtures() {
      this.fixtureList();
    }
  }
}
</script>
<style lang="scss">
@media (max-width: 600px) {

  .v-data-table__wrapper table tbody tr td,
  .v-data-table__wrapper table tbody tr th,
  .v-data-table__wrapper table thead tr td,
  .v-data-table__wrapper table thead tr th,
  .v-data-table__wrapper table tfoot tr td,
  .v-data-table__wrapper table tfoot tr th {
    padding-left: 4px !important;
    padding-right: 2px !important;
  }
}

@media (max-width: 600px) {
  .v-application--is-ltr .v-data-footer__select {
    margin-left: 0 !important;
    /* Remove the left margin */
  }
}

@media (max-width: 600px) {
  .v-application--is-ltr .v-data-footer__pagination {
    margin-left: 5px !important;
    /* Remove the left margin */
  }
}

.v-data-table-header th {
  min-width: 70px;
  /* Set your desired fixed width */
  text-align: left;
  /* Optional: Set alignment for header text */
}

.header-parent {
  display: flex;
  justify-content: space-between;
}

.positioningElement {
  opacity: 0;
  cursor: auto;
}

.inline-block {
  display: inline-block;
}

.grouper {
  display: flex;
  justify-content: flex-end;
}

.eg-table-bnone {
  tr {
    td {
      border-bottom: none !important;
    }
  }
}

.eg-table-td-py-md {
  tr {
    td {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
  }
}

.img_border {
  border-style: solid;
  border-width: 1px;
}

.history-header {
  width: 50px !important;
  /* Width for the header cell */
}

.history-column {
  width: 50px !important;
  /* Width for the body cell */
  text-align: center;
  /* Center the icon */
}
</style>
